import React, { FC, useMemo } from 'react';

import { getMyCompaniesWithInvestmentsTableColumns } from 'constants/investments';
import { CompanyWithInvestments, InvestmentStatuses, TableSorting, UpdateSortingFunctionType } from 'interfaces';
import Table, { TableSkeleton } from 'shared-components/table';
import { filterCompanyWithInvestmentsByInvestmentStatus } from 'utils/investments';

import MyCompaniesWithFinalisedInvestmentsTableItem from './MyCompaniesWithFinalisedInvestmentsTableItem';
import NoFinalisedInvestmentsFound from './NoFinalisedInvestmentsFound';

interface Props {
  companiesWithInvestments: CompanyWithInvestments[];
  updateSorting: UpdateSortingFunctionType;
  isLoading?: boolean;
  sorting: TableSorting;
}

const MyCompaniesWithFinalisedInvestmentsTable: FC<Props> = ({
  companiesWithInvestments,
  isLoading,
  updateSorting,
  sorting,
}) => {
  const companiesWithExchangedInvestments = useMemo(
    () => filterCompanyWithInvestmentsByInvestmentStatus(companiesWithInvestments, InvestmentStatuses.EXCHANGED, true),
    [companiesWithInvestments],
  );

  return (
    <Table columns={getMyCompaniesWithInvestmentsTableColumns()} onUpdateSorting={updateSorting} sorting={sorting}>
      {isLoading && <TableSkeleton rowsNumber={3} columnsNumber={getMyCompaniesWithInvestmentsTableColumns().length} />}

      {!isLoading && !companiesWithExchangedInvestments.length && <NoFinalisedInvestmentsFound />}

      {!isLoading && !!companiesWithExchangedInvestments.length && (
        <>
          {companiesWithExchangedInvestments.map((companyWithInvestments) => {
            const formattedCompanyName = companyWithInvestments.companyName?.slice(0, 40);

            return companyWithInvestments.investments.map((investment, index) => (
              <MyCompaniesWithFinalisedInvestmentsTableItem
                key={investment.id}
                investment={investment}
                companyId={companyWithInvestments.id}
                companyNumber={companyWithInvestments.companyNumber}
                companyName={formattedCompanyName}
                isFirstElement={index === 0}
                companyActiveShareExchange={companyWithInvestments.activeShareExchangeRequest}
              />
            ));
          })}
        </>
      )}
    </Table>
  );
};

export default MyCompaniesWithFinalisedInvestmentsTable;
