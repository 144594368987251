import { omit } from 'lodash';

import { FormItem } from 'interfaces';

import { EXCEPT_FLOAT_NUMBERS_PATTERN, LOCALE_STRING_FORMAT } from '../constants/global';

export const removeLabelFromFormFields = (fields: FormItem[], shouldBeIgnored?: boolean): FormItem[] => {
  if (shouldBeIgnored) return fields;

  return fields.map((field) => {
    return omit(field, ['label']);
  });
};

export const convertNumberInLocaleString = (value: string) => {
  return Number(value.replace(',', '')).toLocaleString(LOCALE_STRING_FORMAT, {
    maximumFractionDigits: 4,
  });
};

export const formatNumberInputValue = (value: string | number, fractionLength?: number, monetaryFigures?: boolean) => {
  const formattedByPatternValue = String(value)?.replace(EXCEPT_FLOAT_NUMBERS_PATTERN, '');

  // Separation of the main and fractional parts
  const splitValueByDot = formattedByPatternValue?.split('.');
  const mainPart = splitValueByDot[0];
  const fraction = splitValueByDot[1];

  // Handle monetaryFigures logic
  if (monetaryFigures) {
    if (fraction?.length >= 3) {
      // Round up to 3 decimals
      const roundedValue = parseFloat(formattedByPatternValue).toFixed(3);
      return convertNumberInLocaleString(roundedValue);
    }
    // Retain the exact number of decimals (0, 1, or 2)
    const preciseValue = parseFloat(formattedByPatternValue).toFixed(fraction?.length || 0);
    return convertNumberInLocaleString(preciseValue);
  }

  // If the last character is a dot and a fractional part is allowed, format the main part and manually add a dot to the end
  if (formattedByPatternValue?.charAt(formattedByPatternValue?.length - 1) === '.' && fractionLength) {
    return convertNumberInLocaleString(mainPart) + '.';
  }

  // Formatting in LocaleString with the addition of a fractional part
  return (
    convertNumberInLocaleString(mainPart) + (fraction?.length ? `.${fraction?.slice(0, fractionLength || 4)}` : '')
  );
};

export const removeComaFromLocaleString = (value: string) => value?.replaceAll(',', '');
