import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  CompaniesIacResponse,
  CompanyIac,
  CompanyIacDeclinePayload,
  CompanyIacResponse,
  CompanyIacStopPayload,
  CreateCompanyIacPayload,
  GetCompanyIacPayload,
  GetListPayload,
  GetListPayloadWithId,
  UpdateCompanyIacPayload,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

import { SignatureData } from '../interfaces/signatures.interfaces';

export class CompaniesIacApis {
  constructor(private url: string) {}

  createCompanyIac({ companyId, fundId }: CreateCompanyIacPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: CompanyIac }>(
      `${this.url}/${companyId}/investment-proposals`,
      { fund_id: fundId },
      config,
    );
  }

  getCompanyIac(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompanyIacResponse>(`${this.url}/${id}/investment-proposals`, config);
  }

  getCompanyIacByIacId({ companyId, iacId }: Omit<GetCompanyIacPayload, 'isAdmin'>, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompanyIacResponse>(`${this.url}/${companyId}/investment-proposals/${iacId}`, config);
  }

  updateCompanyIac(
    { companyId, id, data }: Omit<UpdateCompanyIacPayload, 'shouldUpdateStore'>,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.patch<CompanyIacResponse>(`${this.url}/${companyId}/investment-proposals/${id}`, data, config);
  }

  declineCompanyIac({ id, reason }: CompanyIacDeclinePayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<CompanyIacResponse>(
      `${this.url}/investment-proposals/${id}/decline`,
      { reason },
      config,
    );
  }

  confirmCompanyIac(iacId: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<CompanyIacResponse>(`${this.url}/investment-proposals/${iacId}/confirm`, config);
  }

  stopCompanyIac({ id, reason }: CompanyIacStopPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<CompanyIacResponse>(
      `${this.url}/investment-proposals/${id}/stop-ser`,
      { reason },
      config,
    );
  }

  signCompanyIac(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: SignatureData }>(`${this.url}/investment-proposals/${id}/signatures`, config);
  }

  cancelCompanyIacSignature(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: SignatureData }>(
      `${this.url}/investment-proposals/${id}/cancel-signatures`,
      config,
    );
  }

  setFundManager({ id, fundManagerId }: { id: number; fundManagerId: number }, config?: AxiosRequestConfig) {
    return httpApiClient.patch<CompanyIacResponse>(
      `${this.url}/investment-proposals/${id}/set-fund-manager`,
      { fundManagerId },
      config,
    );
  }

  getArchivedCompanyIac({ id, ...params }: GetListPayloadWithId, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: CompanyIac[] }>(
      `${this.url}/${id}/investment-proposals/archives?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getCompaniesIacList(params: GetListPayload) {
    return httpApiClient.get<CompaniesIacResponse>(`${this.url}/investment-proposals?${stringifyFilterParams(params)}`);
  }
}

export const companiesIacApis = new CompaniesIacApis(`/admin/companies`);
export const adminIacApis = new CompaniesIacApis('/admin');
export const fmUserIacApis = new CompaniesIacApis('/fmusers');
export const fmUserCompaniesIacApis = new CompaniesIacApis('/fmusers/companies');
