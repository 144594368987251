import React, { FC, Fragment, ReactNode } from 'react';

import { LOCALE_STRING_FORMAT } from 'constants/global';
import { Investment } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

import { formatNumberInputValue } from '../../../utils/shared';
import MyInvestmentsNameTableDataContent from '../my-investments/MyInvestmentsNameTableDataContent';

interface Props extends Investment {
  actionsContent: ReactNode;
  extraRow?: ReactNode;
}

const InvestmentsTableItem: FC<Props> = ({
  companyId,
  companyName,
  pricePerShare,
  totalShares,
  investmentDate,
  classOfShares,
  originalInvestmentPrice,
  isChild,
  actionsContent,
  extraRow,
  investmentName,
}) => {
  return (
    <Fragment>
      <TableRow>
        <TableData>
          <MyInvestmentsNameTableDataContent
            isFirstElement={!isChild}
            investmentName={investmentName}
            companyId={companyId}
            companyName={companyName}
          />
        </TableData>
        <TableData>{classOfShares}</TableData>
        <TableData>{totalShares?.toLocaleString(LOCALE_STRING_FORMAT)}</TableData>
        <TableData>£{formatNumberInputValue(pricePerShare, undefined, true)}</TableData>
        <TableData>{investmentDate}</TableData>
        <TableData>£{formatNumberInputValue(originalInvestmentPrice, undefined, true)}</TableData>
        <TableData>{actionsContent}</TableData>
      </TableRow>
      {extraRow}
    </Fragment>
  );
};

export default InvestmentsTableItem;
