import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as EditSvg } from 'assets/svg/pencil.svg';
import { ReactComponent as TrashCan, ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyActiveShareExchange, investmentStatusesColoredLabel, InvestmentWithCompany } from 'interfaces';
import { ButtonsActions, ColoredLabel, LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getCompanyProfileLink, getShareExchangeLink } from 'utils';
import { formatNumberInputValue } from 'utils/shared';
interface Props {
  investment: InvestmentWithCompany;
  companyId: number;
  companyName: string;
  companyNumber: string;
  isFirstElement: boolean;
  companyActiveShareExchange: CompanyActiveShareExchange | null;
}

const MyCompaniesWithFinalisedInvestmentsTableItem: FC<Props> = ({
  investment,
  companyId,
  companyNumber,
  companyName,
  isFirstElement,
  companyActiveShareExchange,
}) => {
  const { originalInvestmentPrice, totalShares, currentInvestmentValue, pricePerShare, classOfShares, investmentDate } =
    investment;

  const getInvestmentButtonsActions = () => [
    {
      id: 'edit-investment',
      icon: EditSvg,
      action: () => {
        return;
      },
      disabled: true,
    },
    {
      id: 'delete-investment',
      icon: DeleteSvg,
      action: () => {
        return;
      },
      disabled: true,
    },
  ];
  return (
    <TableRow className='bg-grey-200'>
      <TableData className='pl-2'>
        <LinkButton
          variant={ButtonVariants.SECONDARY}
          className='w-120'
          to={getShareExchangeLink(companyActiveShareExchange?.id)}
        >
          View
        </LinkButton>
      </TableData>
      <TableData>{isFirstElement && <Link to={getCompanyProfileLink(companyId)}>{companyName}</Link>}</TableData>

      <TableData>{companyNumber}</TableData>
      <TableData>{classOfShares}</TableData>
      <TableData> £{formatNumberInputValue(pricePerShare, undefined, true)}</TableData>
      <TableData>£{formatNumberInputValue(currentInvestmentValue, undefined, true)}</TableData>
      <TableData>{investmentDate ? investmentDate : '-'}</TableData>
      <TableData>{formatNumberInputValue(totalShares, 4)}</TableData>
      <TableData>
        {originalInvestmentPrice && `£${formatNumberInputValue(originalInvestmentPrice, undefined, true)}`}
      </TableData>
      <TableData>
        {investment.status && <ColoredLabel {...investmentStatusesColoredLabel[investment.status]} />}
      </TableData>
      <TableData>
        <ButtonsActions anchorPrefix={investment.id.toString()} actions={getInvestmentButtonsActions()} />
      </TableData>
    </TableRow>
  );
};

export default MyCompaniesWithFinalisedInvestmentsTableItem;
