import React, { FC, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import FormLabel from 'shared-components/form-label/FormLabel';

type Props = {
  onToggle?: (toggle: boolean) => void;
  initial: boolean;
  disabled?: boolean;
  falseLabel: string;
  trueLabel: string;
};

export const TOGGLE_SWITCH_TEST_ID = 'TOGGLE_SWITCH_TEST_ID';

const TOGGLE_CLASS = ' transform translate-x-[37px]';

const ToggleSwitchExtended: FC<Props> = ({ onToggle, initial, disabled, falseLabel, trueLabel }) => {
  const [toggle, setToggle] = useState(initial || false);

  const handleClick = () => {
    if (disabled) return;

    if (onToggle) {
      onToggle(!toggle);
    }
    setToggle(!toggle);
  };

  const setFalseClick = () => {
    if (onToggle) {
      onToggle(false);
    }
    setToggle(false);
  };

  const setTrueClick = () => {
    if (onToggle) {
      onToggle(true);
    }
    setToggle(true);
  };
  return (
    <div className='flex items-center space-x-2 mb-6'>
      <div onClick={() => setFalseClick()}>
        <FormLabel className='mb-0'>{falseLabel}</FormLabel>
      </div>
      <div
        className={twMerge(
          cn('w-[70px] h-[32px] flex items-center rounded-full p-1 cursor-pointer duration-300', {
            'bg-green-600': toggle,
            'bg-grey-300': !toggle,
            'cursor-not-allowed': disabled,
          }),
        )}
        onClick={handleClick}
        data-testid={TOGGLE_SWITCH_TEST_ID}
      >
        <div
          className={cn(
            'bg-white',
            { [TOGGLE_CLASS]: toggle },
            'relative h-[25px] w-[25px] rounded-full shadow-md transition-transform duration-300 ease-in-out',
          )}
        />
      </div>
      <div onClick={() => setTrueClick()}>
        <FormLabel className='mb-0'>{trueLabel}</FormLabel>
      </div>
    </div>
  );
};

export default ToggleSwitchExtended;
