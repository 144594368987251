import React, { useEffect } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { DEALS_PARAMETERS_TABLE_COLUMNS } from 'constants/deals';
import { ConstraintVariants } from 'constants/shared';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getDealsParameters } from 'modules/deals/action';
import { selectDealsParameters } from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout, DealParametersTableRow, NoDealsAdded } from 'page-components';
import { Constraint, Pagination, SearchForm, TitleWithDescription } from 'shared-components';
import DateSelectorFilter from 'shared-components/dateSelectorFilter/DateSelectorFilter';
import Table, { TableSkeleton } from 'shared-components/table';
import { getUserFiltersQuery } from 'utils';

const DEALS_PER_PAGE = 8;

const AllDealParameters = () => {
  const dispatch = useAppDispatch();
  const { isLoading, list: dealsParameters, ...dealsParametersPaginationData } = useAppSelector(selectDealsParameters);

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();
  const { search } = useLocation();
  const { lastActivityDateFrom, lastActivityDateTo } = qs.parse(search);

  useEffect(() => {
    dispatch(
      getDealsParameters({
        page,
        per_page: DEALS_PER_PAGE,
        query,
        order: sorting.order ? sorting.order : 'desc',
        sort: sorting.column ? sorting.column : 'last_action',
        filter: `${filter || ''}&${getUserFiltersQuery({
          lastActivityDateFrom,
          lastActivityDateTo,
        })}`.replace(/&$/, ''),
      }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column, lastActivityDateFrom, lastActivityDateTo]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription title='Deals' className='mb-6' />
        <div className='flex justify-between items-end flex-col lg:flex-row flex-wrap'>
          <SearchForm
            className='w-full lg:w-7/12 mb-12 flex-col flex-row gap-4 gap-0'
            handleFilter={handleFilter}
            placeholder='Search by Company Name'
          />
          <DateSelectorFilter className='pl-0 lg:pl-6 w-full lg:w-5/12 mb-12' />
        </div>
        <Table onUpdateSorting={updateSorting} columns={DEALS_PARAMETERS_TABLE_COLUMNS}>
          {isLoading ? (
            <TableSkeleton rowsNumber={DEALS_PER_PAGE} columnsNumber={DEALS_PARAMETERS_TABLE_COLUMNS.length} />
          ) : dealsParameters?.length && !isLoading ? (
            dealsParameters?.map((deal) => <DealParametersTableRow key={deal.id} {...deal} />)
          ) : (
            <NoDealsAdded />
          )}
        </Table>

        <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...dealsParametersPaginationData} />
      </Constraint>
    </DashboardLayout>
  );
};

export default AllDealParameters;
