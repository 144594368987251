import React, { FC, useEffect, useState } from 'react';

import { format, parse } from 'date-fns';
import { SubmitHandler } from 'react-hook-form';
import { CSSObjectWithLabel } from 'react-select';

import { ReactComponent as PencilIcon } from 'assets/svg/pencil.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can.svg';
import { MONTH_FORMAT_WITH_SLASHES, YEAR_MONTH_FORMAT_WITH_DASH } from 'constants/dateFormats';
import { TableRowVariants } from 'constants/shared';
import { InvestmentInput, ReviewInvestment } from 'interfaces';
import { getCompanyProfileByNumber } from 'modules/companies/action';
import { updateReviewInvestment } from 'modules/investments/action';
import { useAppDispatch } from 'modules/store';
import { TableData, TableRow } from 'shared-components/table';
import { formatNumberInputValue } from 'utils/shared';

import AddInvestmentForm from '../AddInvestmentForm';
import AddInvestmentFormButtons from '../AddInvestmentFormButtons';
import AddInvestmentFormSkeleton from '../AddInvestmentFormSkeleton';

interface Props {
  investment: ReviewInvestment;
  onDelete: (id: string) => void;
}

const customCompanySelectStyles = {
  menuList: (style: CSSObjectWithLabel) => ({ ...style, maxHeight: '120px', width: '280px' }),
  menu: (style: CSSObjectWithLabel) => ({ ...style, width: '280px' }),
};

const ReviewUploadedInvestmentsModalTableItem: FC<Props> = ({ investment, onDelete }) => {
  const dispatch = useAppDispatch();

  const {
    id,
    companyName,
    companyNumber,
    pricePerShare,
    totalShares,
    investmentDate,
    classOfShares,
    amountInvested,
    originalInvestmentPrice,
  } = investment;

  const investmentDefaultValues = {
    companyNumber: { label: companyName, value: companyNumber },
    totalShares: totalShares.toString(),
    pricePerShare,
    investmentDate,
    classOfShares,
    originalInvestmentPrice,
  };

  const [isEditView, setIsEditView] = useState(false);

  const [companyHouseInvestmentDefaultValues, setCompanyHouseInvestmentDefaultValues] =
    useState<Partial<InvestmentInput> | null>(null);
  const [isCompanyHouseLoaded, setIsCompanyHouseLoaded] = useState(false);

  const isCompanyHouseCompanyName = !isNaN(Number(companyName));

  const updateInvestment = (investment: ReviewInvestment) => {
    dispatch(updateReviewInvestment(investment));
  };

  const closeEditView = () => {
    setIsEditView(false);
  };

  const onSubmit: SubmitHandler<InvestmentInput> = ({
    companyNumber: companyNumberValue,
    investmentDate: investmentDateValue,
    totalShares: totalSharesValue,
    classOfShares,
    ...data
  }) => {
    const classOfSharesValue = typeof classOfShares === 'string' ? classOfShares : classOfShares.value;

    const formattedDate = investmentDateValue.includes('/')
      ? investmentDateValue
      : format(parse(investmentDateValue, YEAR_MONTH_FORMAT_WITH_DASH, new Date()), MONTH_FORMAT_WITH_SLASHES);

    if (typeof companyNumberValue === 'string') {
      updateInvestment({
        ...investment,
        totalShares: totalSharesValue,
        investmentDate: formattedDate,
        classOfShares: classOfSharesValue,
        ...data,
      });

      return;
    }

    const { label: updatedCompanyName, value: updatedCompanyNumber } = companyNumberValue;

    updateInvestment({
      ...investment,
      ...data,
      amountInvested: typeof amountInvested === 'string' ? Number(amountInvested?.replaceAll(',', '')) : amountInvested,
      totalShares: totalSharesValue,
      companyName: updatedCompanyName,
      companyNumber: updatedCompanyNumber,
      investmentDate: formattedDate,
      classOfShares: classOfSharesValue,
    });
    closeEditView();
  };

  useEffect(() => {
    if (isEditView && !isNaN(Number(companyName)) && !isCompanyHouseLoaded) {
      dispatch(getCompanyProfileByNumber(companyName))
        .unwrap()
        .then(({ companyName, companyNumber }) => {
          if (!companyName) return;

          setCompanyHouseInvestmentDefaultValues({
            ...investmentDefaultValues,
            companyNumber: { label: companyName, value: companyNumber },
          });
        })
        .finally(() => {
          setIsCompanyHouseLoaded(true);
        });
    }
  }, [companyName, companyNumber, dispatch, isCompanyHouseLoaded, isEditView]);

  if (isEditView && isCompanyHouseCompanyName && !isCompanyHouseLoaded)
    return (
      <TableRow>
        <TableData colSpan={7}>
          <AddInvestmentFormSkeleton />
        </TableData>
      </TableRow>
    );

  if (isEditView) {
    return (
      <TableRow variant={TableRowVariants.SIMPLIFIED} className='last:border-0 [&>td]:last:pb-0'>
        <TableData colSpan={7}>
          <AddInvestmentForm
            enabledCompanySelect
            className='mt-0 py-4'
            companySelectClassName='2xl:min-w-180'
            onSubmitForm={onSubmit}
            defaultValues={
              companyHouseInvestmentDefaultValues !== null
                ? companyHouseInvestmentDefaultValues
                : investmentDefaultValues
            }
            customButtons={<AddInvestmentFormButtons isLoading={false} onCancel={closeEditView} />}
            customCompanySelectStyles={customCompanySelectStyles}
            shouldIgnoreCompanyDataRequest={isCompanyHouseCompanyName || isCompanyHouseLoaded}
            withoutLabels
          />
        </TableData>
      </TableRow>
    );
  }

  return (
    <TableRow className='last:border-0 [&>td]:last:pb-0'>
      <TableData>{companyName}</TableData>
      <TableData>{classOfShares}</TableData>
      <TableData>{formatNumberInputValue(totalShares)}</TableData>
      <TableData>£{formatNumberInputValue(pricePerShare, undefined, true)}</TableData>
      <TableData>{investmentDate}</TableData>
      <TableData>
        {originalInvestmentPrice && `£${formatNumberInputValue(originalInvestmentPrice, undefined, true)}`}
      </TableData>

      <TableData>
        <div className='flex justify-center'>
          <PencilIcon className='mr-5 cursor-pointer' onClick={() => setIsEditView(true)} />
          <TrashCanIcon className='grey-500-svg-path cursor-pointer' onClick={() => onDelete(id)} />
        </div>
      </TableData>
    </TableRow>
  );
};

export default ReviewUploadedInvestmentsModalTableItem;
