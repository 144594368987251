import React, { FC, useState } from 'react';

import { SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { errorNotify } from 'helpers';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { Deal } from 'interfaces';
import { completeDealsParametersById } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';
import { AlertModal } from 'shared-components';

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  selectedDeals: Deal[];
  selectedDealsIds: number[];
  resetSelectedDeals?: VoidFunction;
  isActionLoading?: boolean;
}

const CompleteShareExchangesModal: FC<Props> = ({
  selectedDeals,
  selectedDealsIds,
  resetSelectedDeals,
  isActionLoading,
  ...modalProps
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleCompleteDealsParameters = () => {
    const isNotReadyToComplete = selectedDeals.some(
      (shareExchange) => shareExchange?.stage !== SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE,
    );
    if (isNotReadyToComplete) {
      errorNotify(`Exchanges not in the 'Ready to Complete' stage cannot be completed.`);
      return;
    }

    setIsLoading(true);

    dispatch(completeDealsParametersById(selectedDealsIds))
      .unwrap()
      .then(() => {
        resetSelectedDeals && resetSelectedDeals();
      })
      .finally(() => {
        setIsLoading(false);
        modalProps.onClose();
      });
  };

  return (
    <AlertModal
      onSubmit={() => handleCompleteDealsParameters()}
      isLoading={isActionLoading || isLoading}
      title={'Are you sure you want to complete this Share Exchange?'}
      submitButtonText={'Confirm'}
      modalClassName='lg:w-8/12 xl:w-6/12'
      contentClassName='lg:px-28 lg:py-12'
      wrapperModalClassName='lg:py-0 lg:px-0'
      {...modalProps}
    ></AlertModal>
  );
};

export default CompleteShareExchangesModal;
