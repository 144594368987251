import React, { FC, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { DAXIA_PATTERN } from 'constants/investments';
import { COMPANY_WITH_SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyWithInvestments, InvestmentWithCompany } from 'interfaces';
import MyInvestmentsNameTableDataContent from 'page-components/investments/my-investments/MyInvestmentsNameTableDataContent';
import { Button, ButtonWithTooltip } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getInvestmentsStartShareExchange, getInvestmentsStartShareExchangeRequest, getShareExchangeLink } from 'utils';
import { formatNumberInputValue } from 'utils/shared';

type Props = Pick<
  InvestmentWithCompany,
  'classOfShares' | 'amountInvested' | 'id' | 'investmentName' | 'originalInvestmentPrice'
> &
  Pick<CompanyWithInvestments, 'companyName' | 'userCompanySEStatus'> & {
    companyId: number;
    companyActiveShareExchangeId?: number | null;
    index?: number;
  };

const ACTIONS_BUTTON_ANCHOR_ID_PREFIX = 'actions-button-anchor-';
const BUTTON_STYLES = 'w-full disabled:bg-green-600 disabled:opacity-40 disabled:border-none';

const InvestmentSnippetItem: FC<Props> = ({
  companyName,
  originalInvestmentPrice,
  classOfShares,
  companyId,
  userCompanySEStatus,
  companyActiveShareExchangeId,
  investmentName,
  id,
  index,
}) => {
  const isDaxiaInvestment = investmentName?.includes(DAXIA_PATTERN);

  const navigate = useNavigate();

  const disabledButton = useMemo(
    () =>
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.SE_REACHED_LIMIT,
    [userCompanySEStatus],
  );

  const hasActionsButtonTooltip = useMemo(
    () =>
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.SE_REACHED_LIMIT ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE,
    [userCompanySEStatus],
  );

  const actionButtonText = useMemo(() => {
    if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER || isDaxiaInvestment) {
      return 'View';
    }

    if (
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.SE_REACHED_LIMIT
    ) {
      return 'Exchange';
    }

    if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER) {
      return 'Request';
    }

    return '';
  }, [isDaxiaInvestment, userCompanySEStatus]);

  const tooltipContent = useMemo(() => {
    if (
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.SE_REACHED_LIMIT ||
      userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER
    ) {
      return 'Your request in progress';
    }
    if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE) {
      return 'You can now start share exchange';
    }
    if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER) {
      return 'Click here to initiate a share exchange request';
    }

    return '';
  }, [userCompanySEStatus]);

  const onClickActionButton = () => {
    if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE) {
      navigate(getInvestmentsStartShareExchange(companyId.toString()));
      return;
    }

    if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER) {
      navigate(getInvestmentsStartShareExchangeRequest(companyId.toString()));
      return;
    }

    if (companyActiveShareExchangeId && userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER) {
      navigate(getShareExchangeLink(companyActiveShareExchangeId.toString()));
      return;
    }
  };

  return (
    <TableRow>
      <TableData>
        <MyInvestmentsNameTableDataContent
          linkClassName='decoration-solid'
          investmentName={investmentName}
          companyName={companyName}
          companyId={companyId}
          isDaxia={isDaxiaInvestment}
          isFirstElement
        />
      </TableData>
      <TableData>{classOfShares}</TableData>
      <TableData>£{formatNumberInputValue(originalInvestmentPrice, undefined, true)}</TableData>
      <TableData>
        {hasActionsButtonTooltip ? (
          <ButtonWithTooltip
            anchorId={`${ACTIONS_BUTTON_ANCHOR_ID_PREFIX}-${id}-${index}`}
            tooltipContent={tooltipContent}
            onClick={onClickActionButton}
            className={BUTTON_STYLES}
            disabled={disabledButton}
            variant={ButtonVariants.LINK}
            positionStrategy='fixed'
          >
            {actionButtonText}
          </ButtonWithTooltip>
        ) : (
          <Button className='w-full' variant={ButtonVariants.LINK} onClick={onClickActionButton}>
            {actionButtonText}
          </Button>
        )}
      </TableData>
    </TableRow>
  );
};

export default InvestmentSnippetItem;
