import React, { useEffect, useMemo } from 'react';

import { omit } from 'lodash';
import qs from 'query-string';
import { Control, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { DEAL_PARAMETERS_STATUS_OPTIONS } from 'constants/deals';
import { CompaniesFilterInputs, Option } from 'interfaces';
import history from 'services/history';
import { SelectFormInput } from 'shared-components';

import { COMPANY_DUE_DILIGENCE_STATUS_OPTIONS, COMPANY_IAC_STATUS_OPTIONS } from '../../constants/companies-iac';

const CompaniesPageFilter = () => {
  const { search } = useLocation();

  const parsedSearchParameters = useMemo(() => qs.parse(search), [search]);

  const { control, watch } = useForm<CompaniesFilterInputs>({
    defaultValues: {
      ddStatus: null,
      iacStatus: null,
      lastDealStatus: null,
    },
  });

  const watchDueDiligenceStatus = watch('ddStatus') as Option;
  const watchIacStatus = watch('iacStatus') as Option;
  const watchDealStatus = watch('lastDealStatus') as Option;

  const handleReplaceHistorySearch = (searchParams: Record<string, unknown>) => {
    const newSearchParams = { ...parsedSearchParameters, ...searchParams };
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify(newSearchParams),
    });
  };

  const resetFilters = (resetKey?: string) => {
    const clearParameters = omit(parsedSearchParameters, resetKey ? [`${resetKey}`] : ['ftype', 'fvalue']);
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify({ ...clearParameters, page: 1 }),
    });
  };

  useEffect(() => {
    const searchParams: Record<string, string> = {};

    if (watchDueDiligenceStatus) {
      searchParams.ddStatus = watchDueDiligenceStatus?.value;
    }

    if (watchIacStatus) searchParams.iacStatus = watchIacStatus?.value;

    if (watchDealStatus) searchParams.lastDealStatus = watchDealStatus?.value;

    handleReplaceHistorySearch(searchParams);
  }, [watchDueDiligenceStatus, watchIacStatus, watchDealStatus]);

  return (
    <form className='mb-12 flex gap-4 items-end'>
      <SelectFormInput
        name='iacStatus'
        options={COMPANY_IAC_STATUS_OPTIONS}
        onClearCallback={() => resetFilters('iacStatus')}
        label='Filter by IAC Status'
        placeholder='IAC Status'
        control={control as unknown as Control}
        isClearable
      />
      <SelectFormInput
        label='Filter by Due Diligence Status'
        placeholder='Due Diligence Status'
        control={control as unknown as Control}
        name='ddStatus'
        options={COMPANY_DUE_DILIGENCE_STATUS_OPTIONS}
        onClearCallback={() => resetFilters('ddStatus')}
        isClearable
      />
      <SelectFormInput
        label='Filter by Last Deal Status'
        placeholder='Last Deal Status'
        control={control as unknown as Control}
        name='lastDealStatus'
        options={DEAL_PARAMETERS_STATUS_OPTIONS}
        onClearCallback={() => resetFilters('lastDealStatus')}
        isClearable
      />
    </form>
  );
};

export default CompaniesPageFilter;
