import React, { FC } from 'react';

import { format } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { DealShareExchangeDetails } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';
import { capitalizeFirstLetter } from 'utils';
import { formatNumberInputValue } from 'utils/shared';

const SharesOverviewTableItem: FC<DealShareExchangeDetails> = ({
  classOfShares,
  noOfShares,
  companyPrice,
  fundSharePrice,
  closingDate,
  originalInvestmentPrice,
}) => {
  const calculatedNoExchangesShares = Math.floor((Number(noOfShares) * Number(companyPrice)) / Number(fundSharePrice));

  return (
    <TableRow>
      <TableData>{capitalizeFirstLetter(classOfShares)}</TableData>
      <TableData>{formatNumberInputValue(noOfShares)}</TableData>
      <TableData>£{formatNumberInputValue(companyPrice, undefined, true)}</TableData>
      <TableData>£{formatNumberInputValue(fundSharePrice, undefined, true)}</TableData>
      <TableData>{formatNumberInputValue(calculatedNoExchangesShares)}</TableData>
      <TableData>
        {originalInvestmentPrice !== null
          ? `£${formatNumberInputValue(originalInvestmentPrice, undefined, true)}`
          : '*****'}
      </TableData>
      <TableData>{closingDate ? format(new Date(closingDate), DAY_FORMAT_WITH_SLASHES) : ''}</TableData>
    </TableRow>
  );
};

export default SharesOverviewTableItem;
