import React, { FC, useEffect, useMemo } from 'react';

import { omit } from 'lodash';
import qs from 'query-string';
import { Control, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { FieldTypes } from 'constants/shared';
import { CompaniesFilterInputs } from 'interfaces';
import history from 'services/history';
import { DatePickerInput } from 'shared-components/index';

export const DEFAULT_SEARCH_INPUT_PLACEHOLDER = 'Search by Name, Company, etc..';

type Props = {
  className?: string;
};

const DateSelectorFilter: FC<Props> = ({ className }) => {
  const { search } = useLocation();

  const parsedSearchParameters = useMemo(() => qs.parse(search), [search]);

  const { control, watch } = useForm<CompaniesFilterInputs>({
    defaultValues: {
      lastActivityDateFrom: null,
      lastActivityDateTo: null,
    },
  });

  const watchLastActivityDateFrom = watch('lastActivityDateFrom') as '';
  const watchLastActivityDateTo = watch('lastActivityDateTo') as '';

  const handleReplaceHistorySearch = (searchParams: Record<string, unknown>) => {
    const newSearchParams = { ...parsedSearchParameters, ...searchParams };
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify(newSearchParams),
    });
  };

  const clearURLParameters = () => {
    const clearedParams = omit(parsedSearchParameters, ['lastActivityDateFrom', 'lastActivityDateTo']);
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify(clearedParams),
    });
  };

  useEffect(() => {
    const searchParams: Record<string, string> = {};

    if (watchLastActivityDateFrom) {
      searchParams.lastActivityDateFrom = watchLastActivityDateFrom;
    }

    if (watchLastActivityDateTo) searchParams.lastActivityDateTo = watchLastActivityDateTo;

    handleReplaceHistorySearch(searchParams);
  }, [watchLastActivityDateFrom, watchLastActivityDateTo]);

  useEffect(() => {
    clearURLParameters();
  }, []);

  return (
    <form className={twMerge('flex gap-4 items-between', className)}>
      <DatePickerInput
        name='lastActivityDateFrom'
        control={control as unknown as Control}
        label='Filter by date from'
        labelClassName='labelClassName'
        className='mb-0'
        type={FieldTypes.DATE}
      />
      <DatePickerInput
        name='lastActivityDateTo'
        control={control as unknown as Control}
        label='Filter by date to'
        labelClassName='labelClassName'
        className='mb-0'
        type={FieldTypes.DATE}
      />
    </form>
  );
};

export default DateSelectorFilter;
