import React from 'react';

import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { IsRegisteredData } from 'hooks/useIsAlreadyRegistered';
import { useUserRegistrationFieldFlags } from 'hooks/useUserRegistrationFieldFlags';
import { LinkButton } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getAlreadyRegisteredEmailMessage } from 'utils/messages';

interface Props {
  additionalQueryParams?: string;
  className?: string;
  isAlreadyRegisteredData: IsRegisteredData | null;
  userType: UserTypes;
}

const AlreadyRegisteredInfoSection = ({
  additionalQueryParams,
  isAlreadyRegisteredData,
  className,
  userType,
}: Props) => {
  const { search } = useLocation();

  const navigateRoute = `${ROUTES.signIn}?${search}&${additionalQueryParams}`.replace('??', '?');
  const { isFounder, isInvestor } = isAlreadyRegisteredData || {};

  const { isCorrectFounderFields, isCorrectInvestorFields, isIncorrectFounderFields, isIncorrectInvestorFields } =
    useUserRegistrationFieldFlags(isAlreadyRegisteredData, userType);

  if ((isFounder && isInvestor) || ((isIncorrectFounderFields || isIncorrectInvestorFields) && isAlreadyRegisteredData))
    return (
      <div className={twMerge('flex mb-6', className)}>
        <Typography>Your email is already registered with such type of user. Please,</Typography>
        <LinkButton className='ml-1' to={navigateRoute}>
          login
        </LinkButton>
      </div>
    );

  if ((isFounder || isInvestor) && (isCorrectFounderFields || isCorrectInvestorFields))
    return (
      <div className={twMerge('flex mb-6', className)}>
        <Typography className='text-blue-500'>{getAlreadyRegisteredEmailMessage(userType)}</Typography>
      </div>
    );

  return null;
};

export default AlreadyRegisteredInfoSection;
