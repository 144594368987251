import { TableColumns } from 'interfaces';

import { ColoredLabelVariants } from './shared';

export const DEALS_PAGE_TITLE = 'My Share Exchanges';

export const DEAL_DETAILS_PAGE_OUTSTANDING_TITLE = 'Outstanding Tasks';

export const ADMIN_DEALS_PAGE_TITLE = 'Share Exchanges';

export const DEAL_DETAILS_PAGE_METRICS_SNIPPET_TITLE = 'Investments Metrics';

export const DEAL_DETAILS_PAGE_METRICS_SNIPPET_DESCRIPTION =
  'Overview of all your investments, Click on a company to learn more';

export const DEAL_DETAILS_PAGE_SHARES_OVERVIEW_TITLE = 'Share Exchange Overview';

export const DEAL_DETAILS_INCORRECT_URL_PATTERN = '/a/';

export const DEALS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions', width: 'w-36' },
  { id: 'companyName', title: 'Company Name', width: 'w-5/12', sortable: true },
  { id: 'created_at', title: 'Date Created', sortable: true },
  { id: 'stage', title: 'Stage' },
  { id: 'status', title: 'Status', sortable: true },
  { id: 'icons-actions', title: '', width: 'w-100' },
];

export const INVESTOR_DEALS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'companyName', title: 'Company Name' },
  { id: 'investor', title: 'Investor Name', sortable: true },
  { id: 'paid', title: 'Paid' },
  { id: 'stage', title: 'Stage', sortable: true },
  { id: 'status', title: 'Status', sortable: true },
  { id: 'deal_closing_date', title: 'Closing Date' },
];

export const ADMIN_DEALS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'companyName', title: 'Company Name' },
  { id: 'investor', title: 'Investor Name', sortable: true },
  { id: 'investor_phone', title: 'Investor Phone' },
  { id: 'investorEmail', title: 'Investor Email', sortable: true },
  { id: 'paid', title: 'Paid' },
  { id: 'stage', title: 'Stage', sortable: true },
  { id: 'status', title: 'Status', sortable: true },
  { id: 'deal_closing_date', title: 'Closing Date' },
];

export const DEALS_PARAMETERS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'action', title: '', width: 'w-48' },
  { id: 'id', title: 'Id' },
  { id: 'companyName', title: 'Company Name', sortable: true },
  { id: 'companyNumber', title: 'Company Number' },
  { id: 'deal_id', title: 'Deal Id' },
  { id: 'created_at', title: 'Date Started', sortable: true },
  { id: 'status', title: 'Status', sortable: true },
];

export enum DEALS_TABS_IDS {
  OVERVIEW = 'overview',
  SHARE_EXCHANGES = 'share-exchanges',
}

export enum DEAL_STATUSES {
  IN_PROGRESS = 'inProgress',
  PENDING = 'pending',
  DONE = 'done',
  STOPPED = 'stopped',
  DELETED = 'deleted',
}

export enum DealParametersStatuses {
  LIVE = 'live',
  COMPLETED = 'completed',
  NOT_STARTED = 'not_started',
}

export const dealParametersStatusesLabel: {
  [key in DealParametersStatuses]: {
    children: string;
    variant: ColoredLabelVariants;
  };
} = {
  [DealParametersStatuses.LIVE]: { children: 'Live', variant: ColoredLabelVariants.PROGRESS },
  [DealParametersStatuses.COMPLETED]: { children: 'Completed', variant: ColoredLabelVariants.SUCCESS },
  [DealParametersStatuses.NOT_STARTED]: { children: 'Not started', variant: ColoredLabelVariants.PENDING },
};

export const DEAL_PARAMETERS_STATUS_OPTIONS = [
  { value: DealParametersStatuses.LIVE, label: 'Live' },
  { value: DealParametersStatuses.COMPLETED, label: 'Completed' },
  { value: DealParametersStatuses.NOT_STARTED, label: 'Not started' },
];

export const dealStatusesTextLabel: {
  [key in DEAL_STATUSES]: string;
} = {
  [DEAL_STATUSES.IN_PROGRESS]: 'In Progress',
  [DEAL_STATUSES.PENDING]: 'Pending',
  [DEAL_STATUSES.DONE]: 'Done',
  [DEAL_STATUSES.STOPPED]: 'Stopped',
  [DEAL_STATUSES.DELETED]: 'Deleted',
};

export const dealStatusesLabel: {
  [key in DEAL_STATUSES]: {
    children: string;
    variant: ColoredLabelVariants;
  };
} = {
  [DEAL_STATUSES.IN_PROGRESS]: { children: 'In Progress', variant: ColoredLabelVariants.PROGRESS },
  [DEAL_STATUSES.PENDING]: { children: 'Pending', variant: ColoredLabelVariants.PENDING },
  [DEAL_STATUSES.DONE]: { children: 'Done', variant: ColoredLabelVariants.SUCCESS },
  [DEAL_STATUSES.STOPPED]: { children: 'Stopped', variant: ColoredLabelVariants.ERROR },
  [DEAL_STATUSES.DELETED]: { children: 'Deleted', variant: ColoredLabelVariants.ERROR },
};

export const DEALS_METRICS_DEFAULT_TOTAL_FINISHED_METRICS = {
  totalAll: null,
  totalFinished: {},
  percentOfProgress: null,
};

export const DEALS_METRICS_DEFAULT_TOTAL_STARTED_METRICS = {
  totalAll: null,
  totalStarted: {},
  percentOfProgress: null,
};

export const DEALS_METRICS_DEFAULT_PIE_CHART_STATE = {
  totalInStages: {},
};

export const DEAL_DETAILS_SHARE_EXCHANGES_TABLE_COLUMNS: TableColumns[] = [
  { id: 'classOfShares', title: 'Share Class' },
  { id: 'numberOfShares', title: 'Number of Shares' },
  { id: 'companyPrice', title: 'Company Price (£)' },
  { id: 'daxiaPrice', title: 'Daxia Price (£)' },
  { id: 'numberOfExchangedShares', title: 'Number of Exchanged Shares' },
  { id: 'closingDate', title: 'Closing Date' },
];

export const DEALS_SHARES_OVERVIEW_TABLE_COLUMNS: TableColumns[] = [
  { id: 'classOfShares', title: 'Share Class' },
  { id: 'noOfShares', title: 'Number of Shares' },
  { id: 'companyPrice', title: 'Company Price (£)' },
  { id: 'daxiaPrice', title: 'Daxia Price (£)' },
  { id: 'noExchangedShares', title: 'Number of Shares to Exchange' },
  { id: 'originalInvestmentPrice', title: 'Original Investment Value (£)' },
  { id: 'closingDate', title: 'Anticipated closing date' },
];

export enum SHARE_EXCHANGE_STAGES {
  APPLICATION_FORM = 'application_form',
  APPROPRIATENESS_KYC = 'appropriateness_kyc',
  APPROVAL = 'approval',
  COMPLETED = 'completed',
  INTRODUCER_AGREEMENT = 'introducer_agreement',
  INVOICE = 'invoice',
  READY_TO_COMPLETE = 'ready_to_complete',
  SHARE_EXCHANGE_AGREEMENT = 'share_exchange_agreement',
  STOCK_TRANSFER_FORM = 'stock_transfer_form',
}

export enum SHARE_EXCHANGE_STAGES_LABELS {
  INTRODUCER_AGREEMENT = 'Introducer Agreement',
  APPROPRIATENESS_KYC = 'Investor Appropriateness Questionnaire',
  APPLICATION_FORM = 'Application Form',
  APPROVAL = 'Approval',
  STOCK_TRANSFER_FORM = 'Stock Transfer Form',
}

export enum SHARE_EXCHANGE_DOCUMENT_KEYS {
  INTRODUCER_AGREEMENT = 'introducer_agreement_doc_key',
  APPROPRIATENESS_KYC = 'appropriateness_kyc_doc_key',
  APPLICATION_FORM = 'application_doc_key',
  APPROVAL = 'approval_doc_key',
  SHARE_EXCHANGE_AGREEMENT = 'share_exchange_doc_key',
  INVOICE = 'invoice_id',
  STOCK_TRANSFER_FORM = 'stock_transfer_doc_key',
  READY_TO_COMPLETE = 'ready_to_complete_doc_key',
  COMPLETED = 'investment_confirmation_letter_doc_key',
}

export const SHARE_EXCHANGE_STEPS_DESCRIPTIONS: { [key in SHARE_EXCHANGE_STAGES]: string | null } = {
  [SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT]:
    'See all relevant details about the transaction and eSign to start the process',
  [SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC]:
    'Complete the suitability questionnaire to proceed with the share exchange.',
  [SHARE_EXCHANGE_STAGES.APPLICATION_FORM]: 'Submit details about your investment and exchange.',
  [SHARE_EXCHANGE_STAGES.SHARE_EXCHANGE_AGREEMENT]: 'Confirm the transaction details by eSigning this document.',
  [SHARE_EXCHANGE_STAGES.INVOICE]: 'Complete the payment either via bank transfer or credit card.',
  [SHARE_EXCHANGE_STAGES.STOCK_TRANSFER_FORM]: 'Check the finalised document confirming the share transfer process.',
  [SHARE_EXCHANGE_STAGES.COMPLETED]:
    'Check out the Investment Confirmation Letter with the finalised transaction details.',
  [SHARE_EXCHANGE_STAGES.APPROVAL]: null,
  [SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE]: null,
};
